import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { BroadcastService } from './services/broadcast.service';
import { ClientDetails, ClientDetailsCustomScript, MapClientDetails } from 'src/app/models/client-details';
import { Utils } from './utils';
import { CustomScript } from 'src/types/CustomScript';
import { UILogEvent } from 'src/types/UILogEvent';
import { ScheduledJob, ScheduledJobHistory, ScheduledJobMap, ScheduledJobTaskType } from 'src/types/ScheduledJob';
import { ReviseLeadInfo } from 'src/types/lead-info';
import { LoginResponse } from 'src/types/LoginResponse';
import { LeadsReportsResponse } from 'src/types/ReportingLeads';
@Injectable({
  providedIn: 'root',
})
export class TrademaxAPIService {
  private _baseURL: string = environment.trademaxapiUrl;

  clientDefaultsObserver: ReplaySubject<ClientDetails> = new ReplaySubject<ClientDetails>();

  constructor(
    private http: HttpClient,
    private broadcastService: BroadcastService
  ) {}

  getClientDefaults(apiKey: string): Observable<ClientDetails | null> {
    //TODO: enable this when we go prod.
    const url = `${this._baseURL}get-trademax-config-by-api-key/${apiKey}`;
    //TODO: copy client details as local json
    //const url = `http://localhost:4200/assets/json/us-get-trademax-details-by-api-key.json`;
    const requestOptions = {
      headers: new HttpHeaders({
        'Content-Type': `${environment.applicationType}`,
      }),
    };
    return this.http.get<any>(url, requestOptions).pipe(
      map((data) => {
        const details = MapClientDetails(data);
        this.clientDefaultsObserver.next(details);
        return details;
      })
    );
  }

  updateClientDetails(body: any) {
    return this.http.put(`${this._baseURL}admin/user-config/`, body);
  }

  clear_redis_cache(): Promise<any> {
    return this.http.post<any>(`${this._baseURL}admin/clear-cache`, {}).toPromise();
  }

  getReports() {
    return this.http.get<LeadsReportsResponse>(`${this._baseURL}leads/reports`);
  }

  getLeads(query: any) {
    return this.http.get<any>(`${this._baseURL}leads`, { params: Utils.kvpStripNulls(query, true) });
  }

  getLeadById(lead_id: number, country: 'C' | 'U' = 'U') {
    return this.http.get<any>(`${this._baseURL}leads/${lead_id}`, { params: { country } });
  }

  getLeadsQueue(query: { country: 'U|C'; status: '|DELIVERED|FAILED|PENDING' }) {
    // let q = Utils.kvpStripNulls(query,true)
    return this.http.get<any>(`${this._baseURL}lead-queue/get`, { params: Utils.kvpStripNulls(query, true) });
  }

  getLeadsQueueItem(lead_id: number, country: 'C' | 'U' = 'U') {
    return this.http.get<any>(`${this._baseURL}lead-queue/get/${lead_id}?country=${country}`, {});
  }

  deleteLeadsQueueItem(ids: number[], country: 'C' | 'U' = 'U') {
    return this.http
      .post<any>(`${this._baseURL}lead-queue/delete?country=${country}`, {
        ids: ids,
      })
      .toPromise();
  }
  resetLeadsQueueItems(ids: number[], country: 'C' | 'U' = 'U') {
    return this.http
      .post<any>(`${this._baseURL}lead-queue/reset?country=${country}`, {
        ids: ids,
      })
      .toPromise();
  }
  reRunLeadsQueueTask(country: string) {
    return this.http.post<any>(`${this._baseURL}lead-queue/resend?country=${country}`, {}).toPromise();
  }
  getCustomerEmail(country: 'C' | 'U' = 'U', guid: string) {
    return this.http.get<any>(`${this._baseURL}customer/viewemail/${country}/${guid}`, {}).toPromise();
  }

  getCustomScriptItems() {
    return this.http.get<CustomScript[]>(`${this._baseURL}custom-script`).toPromise();
  }

  getCustomScriptItem(codeName: string) {
    return this.http.get<ClientDetailsCustomScript>(`${this._baseURL}custom-script/${codeName}`, {}).toPromise();
  }

  deleteCustomScriptItem(codeName: string) {
    return this.http.delete<any>(`${this._baseURL}custom-script/${codeName}`, {}).toPromise();
  }
  updateCustomScriptItem(codeName: string, selectedScript: CustomScript) {
    return this.http.put<any>(`${this._baseURL}custom-script/${codeName}`, selectedScript).toPromise();
  }
  createCustomScriptItem(selectedScript: CustomScript) {
    return this.http.post<any>(`${this._baseURL}custom-script`, selectedScript).toPromise();
  }
  logEvent(uiLogEvent: UILogEvent) {
    return this.http.post<any>(`${this._baseURL}ui-event`, uiLogEvent).toPromise();
  }

  getScheduledJobTypes(): Promise<ScheduledJobTaskType[]> {
    return this.http.get<ScheduledJobTaskType[]>(`${this._baseURL}scheduled-job-task-type`).toPromise(); //.then(ret=>ret.map(ScheduledJobMap))
  }

  getScheduledJobType(job_id: number): Promise<ScheduledJobTaskType> {
    return this.http.get<ScheduledJobTaskType>(`${this._baseURL}scheduled-job-task-type/${job_id}`).toPromise(); //.then(ret=>ret.map(ScheduledJobMap))
  }

  getScheduledJobItems(): Promise<ScheduledJob[]> {
    return this.http
      .get<ScheduledJob[]>(`${this._baseURL}scheduled-job`)
      .toPromise()
      .then((ret) => ret.map(ScheduledJobMap));
  }
  getScheduledJobItem(job_id): Promise<ScheduledJob> {
    return this.http.get<ScheduledJob>(`${this._baseURL}scheduled-job/${job_id}`).toPromise().then(ScheduledJobMap);
  }
  createScheduledJobItem(selectedJob: ScheduledJob): Promise<ScheduledJob> {
    return this.http.post<ScheduledJob>(`${this._baseURL}scheduled-job`, selectedJob).toPromise().then(ScheduledJobMap);
  }
  updateScheduledJobItem(id: number, selectedJob: ScheduledJob): Promise<ScheduledJob> {
    return this.http
      .put<ScheduledJob>(`${this._baseURL}scheduled-job/${id}`, selectedJob)
      .toPromise()
      .then(ScheduledJobMap);
  }
  deleteScheduledJobItem(id: number): Promise<any> {
    return this.http.delete<any>(`${this._baseURL}scheduled-job/${id}`).toPromise();
  }

  runScheduledJob(id: number) {
    return this.http.post<any>(`${this._baseURL}scheduled-job/${id}/run`, {}).toPromise();
  }

  get_job_history(job_id: number): Promise<ScheduledJobHistory[]> {
    return this.http.get<any>(`${this._baseURL}scheduled-job/${job_id}/history`).toPromise();
  }
  purge_job_history(job_id: number): Promise<void> {
    return this.http.delete<any>(`${this._baseURL}scheduled-job/${job_id}/history`).toPromise();
  }

  getReviseLeadInfo(lead_guid: string): Observable<ReviseLeadInfo> {
    return this.http.get<any>(`${this._baseURL}revise-lead/${lead_guid}`).pipe(
      map((data) => {
        return data.Data;
      })
    );
  }

  login(username: any, password: any): Promise<LoginResponse> {
    return this.http.post<LoginResponse>(`${this._baseURL}login`, { username, password }).toPromise();
  }

  get_method_configs(): Promise<string[]> {
    return this.http
      .get<any>(`${this._baseURL}lead-queue/method-config`)
      .pipe(
        map((data) => {
          return data.Data;
        })
      )
      .toPromise();
  }
}
